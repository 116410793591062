<template>
  <div>
    <div class="row gutter-b">
        <div class="col-md-12">
          <div class="card card-custom">
            <div class="card-header border-0 px-6">
              <div class="row align-items-center">
                </div>
                <div class="card-toolbar">
                  <b-button variant="success" v-b-modal.modal-foodtype-edit @click="setNewFoodtype()">
                    <span class="svg-icon svg-icon-md svg-icon-white" >
                      <inline-svg src="/media/svg/icons/Navigation/Plus.svg" />
                    </span>
                    {{ $t("mealsystem.foodtypes.button.create") }}
                  </b-button>
                </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <!--begin::Card-->
        <div class="col-md-4 gutter-b" v-for="(foodtype, index) in foodtypes.data" :key="index">
          <div class="card card-custom" >
            <div class="card-header">
              <div class="card-title">
                  <h5 class="card-label">
                  {{ foodtype.name}}
                  </h5>
              </div>
              <div class="card-toolbar"> 
                  <a class="btn btn-link btn-sm btn btn-clean btn-hover-light-danger btn-sm btn-icon" v-b-tooltip.top="$t('mealsystem.foodtypes.title.delete_foodtype')" v-b-modal.modal-foodtype-delete @click="setFoodtype(foodtype)"><i class="ki ki-bold-close icon-sm text-danger"></i></a>
                  <a class="btn btn-link btn-sm btn btn-clean btn-hover-light-primary btn-sm btn-icon" v-b-tooltip.top="$t('button.settings')" v-b-modal.modal-foodtype-edit @click="setFoodtype(foodtype)"><i class="ki ki-gear icon-md"></i></a>
              </div>
            </div>
            <div class="card-body pt-5">
               <span class="text-muted mt-3 font-weight-bold font-size-sm">{{ $t("mealsystem.foodtypes.label.parameters") }}:</span>
               <div class="d-flex align-items-center mt-3">
                  <span class="bullet bullet-bar bg-default align-self-stretch"></span>
                  <div class="d-flex flex-column flex-grow-1 ml-5">
                    <span class="text-dark-75">{{ $t("mealsystem.foodtypes.label.issue_times") }}</span>
                  </div>
                  <strong>{{ foodtype.issue_from | moment("k:mm") }} - {{ foodtype.issue_to | moment("k:mm") }}</strong>
              </div>
              <div class="d-flex align-items-center mt-3">
                  <span class="bullet bullet-bar bg-default align-self-stretch"></span>
                  <div class="d-flex flex-column flex-grow-1 ml-5">
                    <span class="text-dark-75">{{ $t("mealsystem.foodtypes.label.orders_to") }}</span>
                  </div>
                  <span><strong><span class="text-danger">-</span> 1 den</strong> do <strong>{{ foodtype.order_to | moment("k:mm") }}</strong></span>
              </div>
              <div class="d-flex align-items-center mt-3">
                  <span class="bullet bullet-bar bg-default align-self-stretch"></span>
                  <div class="d-flex flex-column flex-grow-1 ml-5">
                    <span class="text-dark-75">{{ $t("mealsystem.foodtypes.label.cancels_to") }}</span>
                  </div>
                  <span><strong><span class="text-success">+</span> 1 den</strong> do <strong>{{ foodtype.cancel_to | moment("k:mm") }}</strong></span>
              </div>
            </div>
          </div>
      </div>
    </div>

    <b-modal id="modal-foodtype-delete" :okTitle="$t('button.delete')" okVariant="danger" :title="$t('mealsystem.foodtypes.title.confirm_delete_foodtype_title')">
       <span v-html="$t('mealsystem.foodtypes.title.confirm_delete_foodtype_text')"></span>
        <template #modal-footer="{ cancel }">
            <b-button variant="secondary" @click="cancel()" class="mr-3">
              {{ $t('button.cancel') }}
          </b-button> 
          <b-button variant="danger" @click="deleteFoodtype(selectedFoodtype)">
            {{ $t('mealsystem.foodtypes.button.delete_foodtype') }}
          </b-button>         
        </template>
    </b-modal>

    <b-modal id="modal-foodtype-edit" :okTitle="$t('button.save')" okVariant="success" :title="$t('mealsystem.foodtypes.title.edit_foodtype')">
        <ValidationObserver ref="form">
            <ValidationProvider name="name" rules="required|min:3" v-slot="{ errors }">
              <span class="text-muted mb-2 d-block">{{ $t('mealsystem.foodtypes.label.name') }}</span>  
              <input 
                    type="text" 
                    class="form-control form-control-lg form-control-solid"
                    :placeholder="$t('mealsystem.foodtypes.placeholder.name')"
                    v-model="selectedFoodtype.name"
              >                
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>

            <span class="text-muted mt-5 mb-2 d-block">{{ $t('mealsystem.foodtypes.label.issue_range') }}</span>  
            <div class="d-flex mb-8">
              <ValidationProvider name="issue_from" rules="required" v-slot="{ errors }">
                <input 
                    type="text" 
                    class="form-control"
                    :placeholder="$t('mealsystem.foodtypes.placeholder.issue_from')"
                    v-model="selectedFoodtype.issue_from"
                >  
               <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <div class="col-2 d-flex align-items-center justify-content-center">-</div>
              <ValidationProvider name="issue_to" rules="required" v-slot="{ errors }">
                <input 
                    type="text" 
                    class="form-control"
                    :placeholder="$t('mealsystem.foodtypes.placeholder.issue_to')"
                    v-model="selectedFoodtype.issue_to"
                > 
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

            
            <div class="d-flex mb-8">
              <ValidationProvider name="order_to" rules="required" v-slot="{ errors }">
                <span class="text-muted mt-5 mb-2 d-block">{{ $t('mealsystem.foodtypes.label.orders_to') }}</span>  
                <input 
                      type="text" 
                      class="form-control"
                      :placeholder="$t('mealsystem.foodtypes.placeholder.order_to')"
                      v-model="selectedFoodtype.order_to"
                  >  
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <div class="col-2 d-flex align-items-center justify-content-center"></div>
              <ValidationProvider name="cancel_to" rules="required" v-slot="{ errors }">
                <span class="text-muted mt-5 mb-2 d-block">{{ $t('mealsystem.foodtypes.label.cancels_to') }}</span>  
                <input 
                      type="text" 
                      class="form-control"
                      :placeholder="$t('mealsystem.foodtypes.placeholder.cancel_to')"
                      v-model="selectedFoodtype.cancel_to"
                  >  
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

        </ValidationObserver>

          <template #modal-footer="{ cancel }">
              <b-button variant="secondary" @click="cancel()" class="mr-3">
                {{ $t('button.cancel') }}
            </b-button> 
            <b-button variant="success" @click="updateFoodtype(selectedFoodtype)">
              {{ $t('mealsystem.foodtypes.button.save_foodtype') }}
            </b-button>         
          </template>
      </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import { checkErrors } from "@/core/utils/api-errors";

export default {
  data() {
    return {
      foodtypes: null,
      loading: false,
      selectedFoodtype: {}
    };
  },
  components: {

  },
  beforeMount() {
   this.getFoodtypes();
  },
    metaInfo () { 
      return { title: this.$t("mealsystem.meta.foodtypes_list")} 
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("mealsystem.meta.foodtypes"), subtitle: this.$t("mealsystem.meta.foodtypes_list") }]);
  },
  methods: {
    getFoodtypes() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet(
        "/meal-system/food-types")
        .then((response) => {
          this.foodtypes = response.data;
          this.loading = false;
        });
    },
  	setFoodtype(foodtype){
    	this.selectedFoodtype = foodtype;
    },
    setNewFoodtype(){
        this.selectedFoodtype = {};
    },
    deleteFoodtype(foodtype){
      this.loading = true;
      ApiService.setHeader();      
      ApiService.apiPost("/meal-system/food-types/" + foodtype.food_id + "/delete")
        .then(() => {
            this.loading = false;
            this.$bvModal.hide('modal-foodtype-delete');
            this.getFoodtypes();
        }).catch(error => {
            checkErrors(error);
      });
    },
    updateFoodtype(foodtype){
      this.loading = true;
      this.$refs.form.validate().then(success => {
        if (!success) {
          this.loading = false;
          this.$toasted.error("Zkontrolujte zadané údaje.");
          return;
        } 

        if(foodtype.food_id){
          this.endpoint = "/meal-system/food-types/" + foodtype.food_id + "/update"
        } else {
          this.endpoint  = "/meal-system/food-types";
        }

        ApiService.setHeader();      
        ApiService.apiPost(this.endpoint,{
          'name': foodtype.name,
          'issue_from': foodtype.issue_from,
          'issue_to': foodtype.issue_to,
          'order_to': foodtype.order_to, 
          'cancel_to': foodtype.cancel_to
        })
          .then(() => {
              this.loading = false;
              this.$bvModal.hide('modal-foodtype-edit');
              this.getFoodtypes();
          }).catch(error => {
              checkErrors(error);
        });
      });
    }
  }
};
</script>
